import {Directive, ElementRef, Input, OnChanges, Renderer2} from '@angular/core';

@Directive({
  selector: '[customDisabled]'
})
export class CustomDisabledDirective implements OnChanges {

  @Input() customDisabled: boolean;
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnChanges() {
    if (this.customDisabled) {
      this.renderer.setAttribute(this.el.nativeElement, 'disabled', 'true');
    } else {
      this.renderer.removeAttribute(this.el.nativeElement, 'disabled');
    }
  }
}
