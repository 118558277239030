import { Injectable } from '@angular/core';
import {Step} from '@app-routes/company/company.model';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {DataService} from '@app-services/data.service';

@Injectable()
export class StepService extends DataService {

  constructor(http: HttpClient) {
    const companyUrl = environment.api_base_url + '/step';
    super(companyUrl, http);
  }

  getAllMapped() {
    return this.getAll().map((data: any) => { return data._embedded.step as Step[]; });
  }
}
