import {Component, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Account } from '../account.model';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Person} from '../../person/person.model';
import {Role, RoleService} from '@app-services/role.service';
import {Store} from '@ngrx/store';

import * as fromAccounts from '@app-accounts-store';
import * as accountActions from '@app-accounts-store/actions';

import * as fromPersons from '@app-persons-store';
import * as personActions from '@app-persons-store/actions';
import {PersonService} from '@app-services/person.service';
import {AccountService} from '@app-services/account.service';
import {LoginService} from "@app-services/login.service";
import {Company} from '@app-routes/company/company.model';
import {Observable} from 'rxjs';
import {CompanyService} from '@app-services/company.service';
import {TypeaheadModel, typeAheadValidator} from '@app-shared-root/typeahead/typeahead.model';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/public_api';


@Component({
  selector: 'app-add-account',
  templateUrl: './add-account.component.html',
  styleUrls: ['./add-account.component.scss']
})
export class AddAccountComponent implements OnInit {
  account: Account;
  person: Person;
  roles: Role[];
  allRoles: Role[];
  twoFAenabled: boolean;
  form;

  activationSend:boolean = false;

  accountCompanies: Company[];
  companies: Company[];
  companyModel:TypeaheadModel;

  constructor(
    private fb: FormBuilder,
    public bsModalRef: BsModalRef,
    private roleService: RoleService,
    private personService: PersonService,
    private accountStore: Store<fromAccounts.State>,
    private personStore: Store<fromPersons.State>,
    private accountService: AccountService,
    private loginService: LoginService,
    private companyService: CompanyService
  ) {

    this.companyModel = new TypeaheadModel();

    if (!this.roles) {
      this.roleService.getRoles()
        .subscribe(data => {
          this.allRoles = data;
          this.roles = data;
          this.roles = this.roles.filter(value => value.value != 'ROLE_TWO_FACTOR_AUTHENTICATED');
          const roleValues: any[] = new Array();
          this.roles.forEach((role: Role) => {
            roleValues.push({[role.value] : [null]});
          });

          const roleFields: object = new Object();
          this.roles.map(role => ( roleFields[role.value] = [null]));

          console.log(roleFields);
          let rolesGroup:FormGroup = this.fb.group(roleFields)

          this.form.removeControl('roles');
          this.form.addControl('roles', rolesGroup);


          console.log(rolesGroup);

          console.log(this.form);
        });
    }
    this.form = this.fb.group({
      'id': [null],
      ['loginname']: [null, [Validators.required, Validators.email]],
      'active': [false],
      'receiveVerloopmail': [true],
      'showhelp': [true],
      'person': [null],
      'roles': [null],
      'company': [null],
      'twoFA': [true]
    });

    this.loginname.valueChanges.subscribe(val => this.onCheckLoginname(val) );
  }

  get loginname() { return this.form.get('loginname'); };

  get company() { return this.form.get('company'); };

  get twoFA() { return this.form.get('twoFA'); };

  ngOnInit() {
    this.companies = Observable.create((observer:any) => {
      this.companyService.searchName(  this.company.value, "COMPANY")
        .subscribe((result : any ) => {
          if ( result) {
            observer.next(result);
          }
        });
    });
  }

  setAccount(account: Account) {
    this.account = account;
    this.form.patchValue(this.account);
    this.form.get('company').setValue("");
    this.setPerson(this.account.person);
    this.roleService.getRoles()
      .subscribe(data => {
        this.setRoles();
      });
    this.twoFAenabled = this.account.roles.map(value => value.value).some(value => value === 'ROLE_TWO_FACTOR_AUTHENTICATED');
    console.log(this.twoFAenabled);
    this.twoFA.checked = this.twoFAenabled;
    this.accountService.getCompanies(account).subscribe(data => {
      this.accountCompanies = data;
    });
  }

  setPerson(person: Person) {
    this.personService.get(person.id).subscribe((rperson: Person) => {
      this.person = rperson;
      console.log(rperson);
      this.form.controls['person'].setValue(rperson._links.self.href);
    });
  }

  onCheckLoginname(v) {
    if ( v.length > 3 ) {
      if (!this.account || !this.account.id) {
        this.loginname.setErrors(null);
        this.accountService.findAccount(v).subscribe((accounts: Account[]) => {
          if (accounts.length > 0) {
            console.log('ERROR, account exists');
            this.loginname.setErrors({'notUnique': true});
          }
        });
      }
    }
  }

  onSelectRole(event, obj: Role) {
    // const role = obj._links.self.href;
    // const roles: FormArray = this.form.get('roles') as FormArray;
    //
    // if (event.target.checked) {
    //   // roles.push(new FormControl(role));
    //   obj['checked'] = true;
    // } else {
    //   obj['checked'] = false;
    //   // let i: number = 0;
    //   //
    //   // roles.controls.forEach((ctrl: FormControl) => {
    //   //   if (ctrl.value === role) {
    //   //     roles.removeAt(i);
    //   //     return;
    //   //   }
    //   //   i++;
    //   // });
    // }
    //
    // console.log(roles);
  }

  onSubmit(form: FormGroup) {
    this.account = form.value;
    console.log(this.account);

    const accountRoles: string[] = new Array();
    this.roles.forEach((role: Role) => {
      if ( this.account.roles[role.value] ) {
        accountRoles.push(role._links['self']['href']);
      }
    });

    console.log(accountRoles);
    console.log( form.value['twoFA'].checked );
    const role: Role = this.allRoles.find(value => value.value === 'ROLE_TWO_FACTOR_AUTHENTICATED');
    if ( form.value['twoFA'].checked ) {
      accountRoles.push(role._links['self']['href']);
    } else {
      const index = accountRoles.indexOf(role._links['self']['href']);
      console.log(index);
      console.log('index role 2fa');
      if ( index > -1 ) {
        accountRoles.slice(index, 1);
      }
    }

    this.account.roles = accountRoles;
    if ( this.accountCompanies ) {
      this.account.companies = this.accountCompanies.map(company => company._links.self.href);
    }
    this.accountCompanies = [];

    if (this.account.id) {
      console.log('dispatch');
      console.log(this.account);
      this.accountStore.dispatch(new accountActions.Update(this.account));
    } else {
      console.log('new::', this.account);
      this.accountStore.dispatch(new accountActions.Create(this.account));
      if ( this.person ) {
        console.log('dispatch person');
        this.person.accountPresent = true;
        this.personStore.dispatch(new personActions.Update(this.person));
      }
    }
    this.bsModalRef.hide();
  }

  setRoles() {

    if ( this.account ) {
      this.account.roles.forEach( (accountRole: Role) => {
        console.log(this.form.get('roles').get(accountRole.value));
        this.form.get('roles').get(accountRole.value).setValue(true);
      });
    }
  }

  hasCompany() {
    return this.form.get('roles').get('ROLE_COMPANY') && this.form.get('roles').get('ROLE_COMPANY').value;
  }

  onSelectCompany(e: TypeaheadMatch) {
    console.log(e.item);
    this.companyModel.setSelectedRef(e.item._links.self.href, this.company.value);
    this.company.updateValueAndValidity();
    this.form.get('company').setValue("");
    if ( !this.accountCompanies ) {
      this.accountCompanies = [];
    }
    if ( this.accountCompanies.indexOf(e.item) < 0 ) {
      this.accountCompanies.push(e.item);
    }
  }

  removeCompany(company: Company) {
    this.accountCompanies.splice(this.accountCompanies.indexOf(company),1);
  }

  sendAccountActivationMail(account_id) {
    console.log("Send activation: " + account_id);
    this.loginService.sendActivationMail(account_id);
    this.activationSend = true;
  }
}

