import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {DataService} from './data.service';
import {Individual} from '@app-routes/individual/individual.model';

@Injectable()
export class IndividualService extends DataService {

  constructor(http: HttpClient) {
    const entryUrl = environment.api_base_url + '/individuals';
    super(entryUrl, http);
  }

  getAllMapped() {
    const params = 'search/open';
    return this.getAll(params).map((data: any) => this.mapIndividual( data));
  }

  getAllConsults() {
    const params = 'search/consults';

    console.log(params);
    return this.getAll(params).map((data: any) => this.mapIndividual( data));
  }

  searchAll(searchQuery: any) {
    const params = 'search/all?person=' + searchQuery['searchQuery']['personName'] + '&closed=' + searchQuery['searchQuery']['closed'];
    return this.getAll(params).map((data: any) => this.mapIndividual( data) );
  }

  mapIndividual(data) {
    if ( data && data._embedded) {
      return data._embedded.individuals as Individual[];
    }
    return [];
  }

  declareIndividual(id: number) {
    const params = 'declaration/' + id;
    return this.getAll(params).map((data: number) => data);
  }

  findSelection(individualSelectionModal: any) {
    return this.create(individualSelectionModal, "selection");
  }

  updateFacturatieStatus(id: number, facturatieStatus: string) {
      let updateValue = {"id": id, "facturatieStatus": facturatieStatus};
      console.log(updateValue);
      return this.update(updateValue);
  }

  getVersion(id: number) {
    const params = 'version/' + id;
    return this.getAll(params);
  }
}
