import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Store} from '@ngrx/store';


import {Company} from '../company.model';
import * as actions from '@app-companies-store/actions';
import * as fromCompanies from '@app-companies-store';
import {FieldsService} from '@app-services/fields.service';
import {StepService} from '@app-services/step.service';
import {Step} from '@app-routes/company/company.model';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-add-company',
  templateUrl: './add-company.component.html',
  styleUrls: ['./add-company.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddCompanyComponent implements OnInit {
  company: Company;
  branchList: any[];
  companyTypeList: any[];
  receiveReportsList: any[];
  customerOfList: Step[];
  selectedCustomerOf: any;
  form: FormGroup;

  constructor(
    public bsModalRef: BsModalRef,
    private fb: FormBuilder,
    private store: Store<fromCompanies.State>,
    private fieldsService: FieldsService,
    private stepService: StepService,
  ) {

    this.companyTypeList = [
      'COMPANY', 'ARBODIENST'
    ];

    this.receiveReportsList = [
      'EMAIL', 'WEBSITE', 'POST'
    ];

    // this.customerOfList = [
    //   { 'id': 1, 'name': 'Step Oost' },
    //   { 'id': 2, 'name': 'Step Zuid' }
    // ];

    this.stepService.getAllMapped().subscribe(res => this.customerOfList = res );

    this.form = fb.group({
      'id': [null],
      'name': [null, Validators.required],
      'branch': [null],
      'companyType': [null, Validators.required],
      'created': [null],
      'modified': [null],
      'afasNumber': [null],
      'customerOf': [null],
      'visitAddress': this.initAddress(),
      'postalAddress': this.initAddress(),
      'phone': [null],
      'fax': [null],
      'email': [null],
      'email2': [null],
      'email3': [null],
      'bijzonderheden': [null],
      'receiveReports': [null],
      'useBestelnummer': [0],
      'bouwendNederland': [false]
    });

  }

  initAddress() {
    return this.fb.group({
      'street': [null],
      'nr': [null],
      'postalcode': [null],
      'city': [null]
    });
  }

  ngOnInit() {

    this.company = this.form.value;
    try {
      this.store.select(fromCompanies.getCurrent)
        .subscribe(company => {
          console.log(company);
          if (company) {
            this.form.patchValue(company);
            this.company = company;

            this.selectedCustomerOf = company.customerOf ? company.customerOf : null;
          }
        });
    } catch(e) {
      console.log(e);
    }
    this.fieldsService.getFieldByType('branch').subscribe(res => this.branchList = res);
  }

  setCompany(company:Company) {
    console.log('set company');
    this.form.patchValue(company);
    this.company = company;

    this.selectedCustomerOf = company.customerOf ? company.customerOf : null;
  }

  get name() { return this.form.get('name'); };
  get branch() { return this.form.get('branch'); };
  get companyType() { return this.form.get('companyType'); };
  get customerOf() { return this.form.get('customerOf'); };
  get receiveReports() { return this.form.get('receiveReports'); };
  get details() { return this.form.get('bijzonderheden'); };

  onSubmit(form: FormGroup) {
    const company: Company = form.value;

    // company.customerOf = this.selectedCustomerOf._links.self.href;

    if (company.id) {
      this.store.dispatch(new actions.Update(company));
    } else {
      this.store.dispatch(new actions.Create(company));
    }

    this.bsModalRef.hide();
  }
}
