import {Injectable} from '@angular/core';
import {DataService} from '@app-services/data.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Zzi} from '@app-services/zzi.service';
import {EmailReceiver} from '@app-routes/company/company.model';

@Injectable()
export class EmailreceiverService {
  url: string;
  headers: any;

  constructor(private http: HttpClient) {
    this.url = environment.api_base_url + '/emailreceivers';
    this.headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
  }

  delete(id) {
    return this.http.delete(this.url + '/' + id, { headers: this.headers}).subscribe();
  }

  update(emailreceiver:EmailReceiver) {
    return this.http.patch(this.url + '/' + emailreceiver.id, emailreceiver );
  }

  create(emailreceiver:EmailReceiver) {
    return this.http.post(this.url, emailreceiver );
  }

  getEmailReceivers(company_id:number) {
    return this.http.get(this.url + '/search/findByCompanyId?company_id=' + company_id, { headers: this.headers})
      .map((data: any) => { return data._embedded.emailreceivers as EmailReceiver[]; });
  }
}
