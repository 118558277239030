import * as actions from './actions';
import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {Person} from '@app-routes/person/person.model';

export const adapter = createEntityAdapter<Person>();

export interface State extends EntityState<Person> {
  editId?: number;
}

export const INIT_STATE: State = adapter.getInitialState({
  editId: undefined
});

export function Reducer(state: State = INIT_STATE, {type, payload}: actions.All) {
  switch (type) {

    case actions.SET_EDIT_ID : {
      return {...state, editId: payload};
    }

    case actions.LOAD_ALL_SUCCESS : {
      return {...state, ...adapter.addAll(payload as Person[], state)};
    }

    case actions.LOAD_SUCCESS : {
      return { ...state, ...adapter.addOne(payload as Person, state) };
    }

    case actions.CREATE_SUCCESS_LOADED : {
      return { ...state, ...adapter.addOne(payload as Person, state)};
    }

    case actions.UPDATE_SUCCESS_LOADED : {
      return {
        ...state,
        ...adapter.updateOne({
          id: payload.id,
          changes: payload
        }, state)
      };
    }

    case actions.DELETE_SUCCESS : {
      return {...state, ...adapter.removeOne(payload, state)};
    }

    case actions.SEARCH_ALL_SUCCESS : {
      return {...state, ...adapter.addAll(payload as Person[], state)};
    }

    default:
      return state;
  }
}

export const getCurrentEditId = (state: State) => state.editId;
