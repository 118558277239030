import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class ItemService {
  private url: string;

  constructor(
    private http: HttpClient
  ) {
    this.url = environment.api_base_url + '/items';
  }

  findItemsByItemCode(itemCode:string) {
    return this.http.get(this.url + '/search/itemcode', { params: {
      itemcode: itemCode
    }}).map((data: any) => { return data as Item[]; });
  }

  findItemsByOmschrijving(omschrijving:string) {
    return this.http.get(this.url + '/search/omschrijving', { params: {
      omschrijving: omschrijving
    }}).map((data: any) => { return data as Item[]; });
  }
}

export interface Item {
  itemCode: string;
  omschrijving: string;
  bedrag: number;
  btwTariefgroep: number;
  btwBedrag: number;
  bedragIncl: number;
}
