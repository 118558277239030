import {Injectable, Injector} from '@angular/core';
import {Pib} from '@app-routes/pib/pib.model';
import {DataService} from '@app-services/data.service';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable()
export class PibService extends DataService {

  private reportUrl:string;

  constructor(http: HttpClient) {
    const pibUrl = environment.api_base_url + '/pib';
    super(pibUrl, http);
    this.reportUrl = pibUrl + "/report/";
  }

  getAllPib() {
    const projection = '?projection=pibProjection';
    return this.getAll(projection).map((data: any) => { return data._embedded.pib as Pib[]; });
  }

  getReportFile(id: number) {
    return this.http.get(this.url + "/" + id + "/report", {responseType: "blob"});
  }

  getReportsZip(id: number) {
    return this.http.get(this.url + "/" + id + "/zip", {responseType: "blob"});
  }

  setReportReadySTEP(id: number, sendmail: boolean) {
    return this.http.get(this.reportUrl + id + "/reportreadystep?sendmail=" + sendmail);
  }

  setReportReadyCompany(id: number, sendmail: boolean) {
    return this.http.get(this.reportUrl + id + "/reportreadycompany?sendmail=" + sendmail);
  }

}
