import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DataService} from '@app-services/data.service';
import {environment} from '../../environments/environment';
import {Indextype} from '@app-routes/indextype/indextype.model';

@Injectable()
export class IndextypeService extends DataService {

  constructor(http: HttpClient) {
    const fieldsUrl = environment.api_base_url + '/indextypes';
    super(fieldsUrl, http);
  }

  getIndexTypes() {
    return this.getAll().map((data: any) => { return data._embedded.indextypes as Indextype[]; });
  }


}
