import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilter',
  pure: false
})
export class SearchFilterPipe implements PipeTransform {
  transform(items, filter: { [key: string]: any, value: string }) {
     items =  items.filter(item => {
      const notMatchingField = Object.keys(filter)
        .find(key => {
          let match = true;
          if (filter[key] !== null ) {
            const regExp = new RegExp(filter[key], 'gi');
            match = regExp.test(item[key]);
          }
          return !match;
        });
        return !notMatchingField;
      });
    return items;
  }

}
