import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '@app-services/auth.service';

@Injectable()
export class DataService {

  constructor(
    protected url: string,
    public http: HttpClient,
    public authService?: AuthService
  ) {
  }

  getAll(params?) {
    let url = this.url;
    if (params) url = url + '/' + params;

    return this.http.get(url);
  }

  get(id, params?) {
    let url = this.url + '/' + id;
    if (params) url = url + '/' + params;
    return this.http.get(url);
  }

  create(resource, params?) {
    let url = this.url;
    if (params) url = url + '/' + params;
    return this.http.post(url, resource);
  }

  update(resource, params?) {
    console.log(resource);
    let url = this.url + '/' + resource.id;
    if (params) url = url + '/' + params;
    return this.http.patch(url, resource);
  }

  delete(id) {
    return this.http.delete(this.url + '/' + id);
  }

  getByUrl(url: string) {
    return this.http.get(url);
  }
}
