
import {Action} from '@ngrx/store';
import {Entry} from '@app-routes/entry/entry.model';

export const LOAD_ALL = '[entries] LOAD ALL';
export const LOAD_ALL_SUCCESS = '[entries] LOAD SUCCESS';

export const LOAD = '[entry] LOAD';
export const LOAD_SUCCESS = '[entry] LOAD SUCCESS';

export const CREATE = '[entry] CREATE';
export const CREATE_SUCCESS = '[entry] CREATE SUCCESS';
export const CREATE_SUCCESS_LOADED = '[entry] CREATE SUCCESS LOADED';

export const UPDATE = '[entry] UPDATE';
export const UPDATE_SUCCESS = '[entry] UPDATE SUCCESS';

export const DELETE = '[entry] DELETE';
export const DELETE_SUCCESS = '[entry] DELETE SUCCESS';

export const SET_EDIT_ID = '[entry] SET EDIT ID';

export class SetEditID implements Action {
  readonly type = SET_EDIT_ID;
  constructor(public payload: number) {}
}

export class LoadAll implements Action {
  readonly type = LOAD_ALL;
  constructor(public payload = null) {};
}

export class Load implements Action {
  readonly type = LOAD;
  constructor(public payload: number) {}
}

export class Create implements Action {
  readonly type = CREATE;
  constructor(public payload: Entry) {};
}

export class Update implements Action {
  readonly type = UPDATE;
  constructor(public payload: Entry) {};
}

export class Delete implements Action {
  readonly type = DELETE;
  constructor(public payload: number) {};
}

export class LoadAllSuccess implements Action {
  readonly type = LOAD_ALL_SUCCESS;
  constructor(public payload: Entry[]) {
  }
}

export class LoadSuccess implements Action {
  readonly type = LOAD_SUCCESS;
  constructor(public payload: Entry) {}
}

export class CreateSuccess implements Action {
  readonly type = CREATE_SUCCESS;
  constructor(public payload: Entry) {}
}

export class CreateSuccessLoaded implements Action {
  readonly type = CREATE_SUCCESS_LOADED;
  constructor(public payload: Entry) {}
}

export class UpdateSuccess implements Action {
  readonly type = UPDATE_SUCCESS;
  constructor(public payload: Entry) {}
}

export class DeleteSuccess implements Action {
  readonly type = DELETE_SUCCESS;
  constructor(public payload: number) {}
}

export type All =
  | SetEditID
  | LoadAll
  | Load
  | Create
  | Update
  | Delete
  | LoadAllSuccess
  | LoadSuccess
  | UpdateSuccess
  | CreateSuccess
  | CreateSuccessLoaded
  | DeleteSuccess;
