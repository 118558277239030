import {ErrorHandler, ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {ToasterModule} from 'angular2-toaster/angular2-toaster';

import {AccordionModule} from 'ngx-bootstrap/accordion';
import {AlertModule} from 'ngx-bootstrap/alert';
import {ButtonsModule} from 'ngx-bootstrap/buttons';
import {CarouselModule} from 'ngx-bootstrap/carousel';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {ModalModule} from 'ngx-bootstrap/modal';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {ProgressbarModule} from 'ngx-bootstrap/progressbar';
import {RatingModule} from 'ngx-bootstrap/rating';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {TimepickerModule} from 'ngx-bootstrap/timepicker';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {TypeaheadModule} from 'ngx-bootstrap/typeahead';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';

import {FlotDirective} from './directives/flot/flot.directive';
import {SparklineDirective} from './directives/sparkline/sparkline.directive';
import {EasypiechartDirective} from './directives/easypiechart/easypiechart.directive';
import {ColorsService} from './colors/colors.service';
import {CheckallDirective} from './directives/checkall/checkall.directive';
import {VectormapDirective} from './directives/vectormap/vectormap.directive';
import {NowDirective} from './directives/now/now.directive';
import {ScrollableDirective} from './directives/scrollable/scrollable.directive';
import {JqcloudDirective} from './directives/jqcloud/jqcloud.directive';
import {MomentModule} from 'angular2-moment';
import { ScrollToModule } from 'ng2-scroll-to-el';

import { CustomDisabledDirective } from './directives/customdisable/custom-disabled.directive';

import {AppErrorHandler} from '../common/app-error-handler';
import {TableSearchComponent} from './components/table-search/table-search.component';
import {AddAccountComponent} from '../routes/account/add-account/add-account.component';

import {KeysPipe} from '@app-shared-pipes/keys.pipe';
import {SearchFilterPipe} from '@app-shared-pipes/search-filter.pipe';
import {ExcludeFilterPipe} from '@app-shared-pipes/exclude-filter.pipe';

import {StepService} from '@app-services/step.service';
import {IndexesService} from '@app-services/indexes.service';
import {IconsultsService} from '@app-services/iconsults.service';
import {LessonsService} from '@app-services/lessons.service';
import {FieldsService} from '@app-services/fields.service';
import {AuthService} from '@app-services/auth.service';
import {RoleService} from '@app-services/role.service';
import {MenuitemService} from '@app-services/menuitem.service';
import {SearchService} from '@app-services/search.service';
import {PersonService} from '@app-services/person.service';
import {ReportService} from '@app-services/report.service';
import {AccountService} from '@app-services/account.service';
import {CompanyService} from '@app-services/company.service';
import {EntryService} from '@app-services/entry.service';
import {IndividualService} from '@app-services/individual.service';
import {IndextypeService} from '@app-services/indextype.service';
import {ItemService} from '@app-services/item.service';
import {DashboardService} from "@app-services/dashboard.service";
import {ZziService} from "@app-services/zzi.service";
import {DeclarationService} from "@app-services/declaration.service";
import {EmailreceiverService} from '@app-services/emailreceiver.service';
import {PibService} from '@app-services/pib.service';
import {PiblessonService} from '@app-services/piblesson.service';
import {ArtikelService} from '@app-services/artikel.service';


import {AddPersonComponent} from "@app-routes/person/add-person/add-person.component";
import {AddCompanyComponent} from "@app-routes/company/add-company/add-company.component";
import {CompanyEntryComponent} from '@app-routes/home/company-entry/company-entry.component';
import {OrderModule} from 'ngx-order-pipe';
import {NestedSearchFilterPipe} from '@app-shared-pipes/nested-search-filter.pipe';
import {PibCompanyService} from '@app-services/pibcompany.service';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    AccordionModule.forRoot(),
    AlertModule.forRoot(),
    ButtonsModule.forRoot(),
    CarouselModule.forRoot(),
    CollapseModule.forRoot(),
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    ProgressbarModule.forRoot(),
    RatingModule.forRoot(),
    TabsModule.forRoot(),
    TimepickerModule.forRoot(),
    TooltipModule.forRoot(),
    TypeaheadModule.forRoot(),
    ToasterModule,
    MomentModule,
    ScrollToModule.forRoot(),
    OrderModule
  ],
  providers: [
    ColorsService,
    AuthService,
    RoleService,
    MenuitemService,
    SearchService,
    PersonService,
    AccountService,
    CompanyService,
    EntryService,
    IndividualService,
    IndexesService,
    IndextypeService,
    IconsultsService,
    EmailreceiverService,
    LessonsService,
    ReportService,
    FieldsService,
    StepService,
    ItemService,
    DashboardService,
    ZziService,
    DeclarationService,
    PibService,
    PiblessonService,
    PibCompanyService,
    ArtikelService,
    { provide: ErrorHandler, useClass: AppErrorHandler }
  ],
  declarations: [
    FlotDirective,
    SparklineDirective,
    EasypiechartDirective,
    CheckallDirective,
    VectormapDirective,
    NowDirective,
    ScrollableDirective,
    JqcloudDirective,
    SearchFilterPipe,
    NestedSearchFilterPipe,
    ExcludeFilterPipe,
    TableSearchComponent,
    AddAccountComponent,
    AddPersonComponent,
    AddCompanyComponent,
    CompanyEntryComponent,
    KeysPipe,
    CustomDisabledDirective
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
    AccordionModule,
    AlertModule,
    ButtonsModule,
    CarouselModule,
    CollapseModule,
    BsDropdownModule,
    BsDatepickerModule,
    PaginationModule,
    ProgressbarModule,
    RatingModule,
    TabsModule,
    TimepickerModule,
    TooltipModule,
    TypeaheadModule,
    ToasterModule,
    FlotDirective,
    SparklineDirective,
    EasypiechartDirective,
    CheckallDirective,
    VectormapDirective,
    NowDirective,
    ScrollableDirective,
    JqcloudDirective,
    ModalModule,
    MomentModule,
    SearchFilterPipe,
    NestedSearchFilterPipe,
    ExcludeFilterPipe,
    TableSearchComponent,
    AddAccountComponent,
    AddPersonComponent,
    AddCompanyComponent,
    CompanyEntryComponent,
    ScrollToModule,
    KeysPipe,
    CustomDisabledDirective,
    OrderModule
  ],
  entryComponents: [
    AddAccountComponent,
    AddPersonComponent,
    AddCompanyComponent,
    CompanyEntryComponent
  ]
})

// https://github.com/ocombe/ng2-translate/issues/209
export class SharedModule {
  private static StoreDevtoolsModule: any;
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule
    };
  }
}

// export function authHttpServiceFactory(http: HttpClient, options: RequestOptions) {
//   return new AuthHttp(new AuthConfig({
//     headerName: 'Authorization',
//     headerPrefix: 'bearer',
//     tokenName: 'token',
//     tokenGetter: (() => localStorage.getItem('token')),
//     globalHeaders: [{ 'Content-Type': 'application/json' }],
//     noJwtError: true
//   }), http, options);
// }
