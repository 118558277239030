import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {DataService} from "@app-services/data.service";
import {Indextype} from "@app-routes/indextype/indextype.model";

@Injectable()
export class IndexesService {url: string;
  headers: any;

  constructor(
    private http: HttpClient
  ) {
    this.url = environment.api_base_url;
    // TODO: SPRING missing Access-Control-Allow-Headers: contenttype in preflight
    this.headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
  }

  create(resource) {
    return this.http.post(this.url + '/indexes', resource, { headers: this.headers});
  }

  update(id, resource) {
    return this.http.patch(this.url + `/indexes/${id}`, resource, { headers: this.headers});
  }

  delete(id) {
    return this.http.delete(this.url + `/indexes/${id}`, { headers: this.headers});
  }
}
