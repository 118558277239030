import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {DataService} from './data.service';
import {Company} from '@app-routes/company/company.model';

@Injectable()
export class CompanyService extends DataService {
  constructor(http: HttpClient) {
    const companyUrl = environment.api_base_url + '/companies';
    super(companyUrl, http);
  }

  getAllMapped() {
    const params = '?sort=modified&modified.dir=desc';
    return this.getAll(params).map((data: any) => {  if ( data && data._embedded) { return data._embedded.companies as Company[]; }});
  }

  searchName(name: string, type: string) {
    const params = `search/all?name=${name}&companyType=${type}`;
    return this.getAll(params).map((data: any) => { if ( data && data._embedded) { return data._embedded.companies as Company[]; }});
  }

  searchNameExtend(name: string, type: string) {
    const params = `search/all?name=${name}&companyType=${type}&extend=true`;
    return this.getAll(params).map((data: any) => { if ( data && data._embedded) { return data._embedded.companies as Company[]; }});
  }

  searchAll(name: string) {
    const params = 'search/all?name=' + name + '&extend=true';
    return this.getAll(params).map((data: any) => { if ( data && data._embedded) { return data._embedded.companies as Company[]; }});
  }

  getByType(type) {
    const params = `search/all?companyType=${type}&extend=true`;
    return this.getAll(params).map((data: any) => { if ( data && data._embedded) { return data._embedded.companies as Company[]; }});
  }

  getAccountCompanies() {
    return this.getAll("account").map((data: any) => { if ( data && data._embedded) { return data._embedded.companies as Company[]; }});
  }
}
