import {Injectable} from '@angular/core';
import {DataService} from '@app-services/data.service';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable()
export class ArtikelService extends DataService {


  constructor(http: HttpClient) {
    const pibUrl = environment.api_base_url + '/artikel';
    super(pibUrl, http);
  }
}
