import { Injectable } from '@angular/core';
import {NavigationCancel, Router} from '@angular/router';
import {environment} from '../../environments/environment';
import {AuthService} from 'app/services/auth.service';

import 'rxjs/add/operator/map';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {MenuService} from "../core/menu/menu.service";
import {filter} from 'rxjs/operators';

@Injectable()
export class LoginService {

  public referrer: String;
  headers: HttpHeaders = new HttpHeaders();

  constructor(
    private router: Router,
    private http: HttpClient,
    private authService: AuthService,
    private menuService: MenuService
  ) {
    console.log('Login service constructed');

    this.router.events.pipe(
          filter(e => e instanceof NavigationCancel)
        ).subscribe((e: NavigationCancel) => {
        console.log('Referer: ' + e.url );
        this.referrer = e.url;
      });

    this.headers = this.headers.set('Authorization', 'Basic ' + btoa(environment.auth_client_id + ':' + environment.auth_client_secret));
  }

  obtainAccessToken(loginData) {
    if (this.authService.currentUser === loginData.username) {
      return;
    }

    localStorage.removeItem('2fap');
    localStorage.removeItem('token');
    localStorage.removeItem('username');

    let params = new HttpParams();
    params = params.set('username', loginData.username);
    params = params.set('password', loginData.password);
    params = params.set('grant_type', 'password');

    const headers = this.headers;
    headers.set('Content-Type', 'application/x-www-form-urlencoded');

    return this.http.post<any>(environment.auth_server_url, '', { headers: headers, params: params })
      .map(response => {
        if (response && response.access_token) {
          localStorage.setItem('token', response.access_token);
          localStorage.setItem('username', loginData.username);
          return true;
        }
        return false;
      });
  }

  checkToken() {
    if (this.authService.isLoggedIn()) {

      if ( this.authService.isTwoFaRequired()  ) {
        this.router.navigate(['/2fa']);
      }

      this.menuService.loadMenuItems();
      return true;
    }

    this.router.navigate(['/login']);
    return false;
  }

  forgotPassword(data) {
    const params = new HttpParams().set('loginname', data.email);
    return this.http.post(environment.api_base_url + '/accountfunctions/pwdlost', '', { headers: this.headers, params: params });
  }

  resetPassword(uuid, data) {
    const params = new HttpParams().set('password', data.password);
    return this.http.post(environment.api_base_url + '/accountfunctions/activate/' + uuid, '', { headers: this.headers, params: params, responseType: 'text' });
  }

  sendActivationMail(account_id) {
    console.log("activation email start");

    let url = environment.api_base_url + '/accountfunctions/send_activation_mail/' + account_id;
    console.log(url);
    this.http.post(url, '', { headers: this.headers}).subscribe();
    console.log("activation email send");
  }


  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('2fap');
    this.router.navigate(['/login']);
  }
}
