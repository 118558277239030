import {AbstractControl, ValidatorFn} from "@angular/forms";

export class TypeaheadModel {

  selectedRef: String = "";
  typedValue: String = "";

  constructor() { }

  public getSelectedRef() {
    return this.selectedRef;
  }

  public setSelectedRef(selectedRef:String, typedValue:String) {
    this.selectedRef = selectedRef;
    this.typedValue = typedValue;
  }
}

export function typeAheadValidator(model:TypeaheadModel): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} => {
    if ( control.value == null || control.value == "" ) {
      return null;
    }
    if ( model.typedValue !== control.value ) {
      console.log({'unknownValue': {value: control.value}});
      return {'unknownValue': {value: control.value}};
    }
    let isValid:boolean = (control.value !=null && control.value != "" && model.getSelectedRef().startsWith("http") );
    return isValid? null: {'unknownValue': {value: control.value}}};
}
