import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nestedSearchFilter',
  pure: false
})
export class NestedSearchFilterPipe implements PipeTransform {
  transform(items, filter: { 'fields': [string[]], value: string }) {
    const filteredItems = items.filter(item => {
      const fields = filter['fields'];
      let match = true;
      fields.forEach((field: Array<string>) => {
        const regExp = new RegExp(filter.value, 'gi');
        if ( field.length === 1 ) {
          match = match && !regExp.test(item[field[0]]);
        } else if ( field.length === 2 ) {
          match = match && !regExp.test(item[field[0]][field[1]]);
        } else if ( field.length === 3 ) {
          match = match && !regExp.test(item[field[0]][field[1]][field[2]]);
        } else if ( field.length === 4 ) {
          match = match && !regExp.test(item[field[0]][field[1]][field[2]][field[3]]);
        }
      });
      return !match;
    });
    return filteredItems;
  }

}
