import {Company} from "@app-routes/company/company.model";
import {Address} from '@app-shared-models/address';

export interface Person {
  id: number;
  dob: Date;
  dobYear: number;
  fullName?: string;
  formalName?: string;
  initials: string;
  firstnames?: string;
  infix?: string;
  lastname: string;
  sex: string;

  address?: Address;

  phone?: string;
  mobile?: string;
  sofinumber?: string;
  function?: string;
  dienstverband?: number;
  email?: string;
  insurernumber?: string;
  company?: Company;
  companyName?: string;
  receiveReport?: boolean;

  accountPresent: boolean;

  _links: any;
}

export class PersonSimple {

  constructor(
    public id: number,
    public fullName: string,
  ) {
    this.id = this.id;
    this.fullName = this.fullName;
  }
}

export class PersonImpl implements Person {
  _links: any;
  accountPresent: boolean;
  company: Company;
  companyName: string;
  dienstverband: number;
  dob: Date;
  dobYear: number;
  email: string;
  firstnames: string;
  formalName: string;
  fullName: string;
  function: string;
  id: number;
  infix: string;
  initials: string;
  insurernumber: string;
  lastname: string;
  mobile: string;
  phone: string;
  sex: string;

  address?: Address;
  sofinumber: string;
}
