import {APP_INITIALIZER, NgModule} from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslatorService } from '../core/translator/translator.service';
import { MenuService } from '../core/menu/menu.service';
import { SharedModule } from '@app-shared-root/shared.module';

import { menu } from './menu';
import { routes } from './routes';
import { LoginComponent } from './auth/login/login.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';

import { ActivateComponent } from './auth/activate/activate.component';
import { TwofaComponent } from './auth/twofa/twofa.component';


@NgModule({
    imports: [
        SharedModule,
        RouterModule.forRoot(routes)
    ],
    declarations: [LoginComponent, ForgotPasswordComponent, ResetPasswordComponent, ActivateComponent, TwofaComponent],
    exports: [
        RouterModule
    ]
})

export class RoutesModule {
    constructor(public menuService: MenuService, tr: TranslatorService) {
        // menuService.addMenu(menu);
    }
}
