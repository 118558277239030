
import {Action} from '@ngrx/store';
import {Person} from '@app-routes/person/person.model';

export const LOAD_ALL = '[persons] LOAD ALL';
export const LOAD_ALL_SUCCESS = '[persons] LOAD SUCCESS';

export const LOAD = '[person] LOAD';
export const LOAD_SUCCESS = '[person] LOAD SUCCESS';

export const CREATE = '[person] CREATE';
export const CREATE_SUCCESS = '[person] CREATE SUCCESS';
export const CREATE_SUCCESS_LOADED = '[person] CREATE SUCCESS LOADED';

export const UPDATE = '[person] UPDATE';
export const UPDATE_SUCCESS = '[person] UPDATE SUCCESS';
export const UPDATE_SUCCESS_LOADED = '[person] UPDATE SUCCESS LOADED';

export const DELETE = '[person] DELETE';
export const DELETE_SUCCESS = '[person] DELETE SUCCESS';

export const SET_EDIT_ID = '[person] SET EDIT ID';

export const SEARCH_ALL = '[person] SEARCH ALL';
export const SEARCH_ALL_SUCCESS = '[person] SEARCH ALL SUCCESS';


export class SetEditID implements Action {
  readonly type = SET_EDIT_ID;
  constructor(public payload: number) {}
}

export class LoadAll implements Action {
  readonly type = LOAD_ALL;
  constructor(public payload = null) {};
}

export class Load implements Action {
  readonly type = LOAD;
  constructor(public payload: number) {}
}

export class Create implements Action {
  readonly type = CREATE;
  constructor(public payload: Person) {};
}

export class Update implements Action {
  readonly type = UPDATE;
  constructor(public payload: Person) {};
}

export class Delete implements Action {
  readonly type = DELETE;
  constructor(public payload: number) {};
}

export class LoadAllSuccess implements Action {
  readonly type = LOAD_ALL_SUCCESS;
  constructor(public payload: Person[]) {}
}

export class LoadSuccess implements Action {
  readonly type = LOAD_SUCCESS;
  constructor(public payload: Person) {}
}

export class CreateSuccess implements Action {
  readonly type = CREATE_SUCCESS;
  constructor(public payload: Person) {}
}


export class CreateSuccessLoaded implements Action {
  readonly type = CREATE_SUCCESS_LOADED;
  constructor(public payload: Person) {}
}


export class UpdateSuccess implements Action {
  readonly type = UPDATE_SUCCESS;
  constructor(public payload: Person) {}
}

export class UpdateSuccessLoaded implements Action {
  readonly type = UPDATE_SUCCESS_LOADED;
  constructor(public payload: Person) {}
}


export class DeleteSuccess implements Action {
  readonly type = DELETE_SUCCESS;
  constructor(public payload: number) {}
}


export class SearchAll implements Action {
  readonly type = SEARCH_ALL;
  constructor(public payload: object) {};
}

export class SearchAllSuccess implements Action {
  readonly type = SEARCH_ALL_SUCCESS;
  constructor(public payload: Person[]) {}
}

export type All =
  | SetEditID
  | LoadAll
  | Load
  | Create
  | Update
  | Delete
  | LoadAllSuccess
  | LoadSuccess
  | UpdateSuccess
  | CreateSuccess
  | CreateSuccessLoaded
  | UpdateSuccessLoaded
  | DeleteSuccess
  | SearchAll
  | SearchAllSuccess;
