import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable()
export class SearchService {

  constructor(
    private http: HttpClient
  ) {}

  doSearch(value: string) {
    console.error('running search:', value);
  }

  searchAccounts(params: any) {
    const url = environment.api_base_url + '/accounts';
    console.log(params);
    // this.http.get(url, { params: params );
  }
}
