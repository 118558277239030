
import {Action} from '@ngrx/store';
import {Account} from '@app-routes/account/account.model';

export const LOAD_ALL = '[accounts] LOAD ALL';
export const LOAD_ALL_SUCCESS = '[accounts] LOAD SUCCESS';

export const LOAD = '[account] LOAD';
export const LOAD_SUCCESS = '[account] LOAD SUCCESS';

export const FIND = '[accounts] FIND';
export const FIND_SUCCESS = '[accounts] FIND SUCCESS';

export const CREATE = '[account] CREATE';
export const CREATE_SUCCESS = '[account] CREATE SUCCESS';
export const CREATE_SUCCESS_LOADED = '[account] CREATE SUCCESS LOADE';

export const UPDATE = '[account] UPDATE';
export const UPDATE_SUCCESS = '[account] UPDATE SUCCESS';
export const UPDATE_SUCCESS_LOADED = '[account] UPDATE SUCCESS LOADED';

export const DELETE = '[account] DELETE';
export const DELETE_SUCCESS = '[account] DELETE SUCCESS';

export const SET_EDIT_ID = '[account] SET EDIT ID';

export class SetEditID implements Action {
  readonly type = SET_EDIT_ID;
  constructor(public payload: number) {}
}

export class LoadAll implements Action {
  readonly type = LOAD_ALL;
  constructor(public payload = null) {};
}

export class Load implements Action {
  readonly type = LOAD;
  constructor(public payload: number) {}
}

export class Find implements Action {
  readonly type = FIND;
  constructor(public payload: string) {}
}

export class Create implements Action {
  readonly type = CREATE;
  constructor(public payload: Account) {};
}

export class Update implements Action {
  readonly type = UPDATE;
  constructor(public payload: Account) {};
}

export class Delete implements Action {
  readonly type = DELETE;
  constructor(public payload: number) {};
}

export class LoadAllSuccess implements Action {
  readonly type = LOAD_ALL_SUCCESS;
  constructor(public payload: Account[]) {}
}

export class LoadSuccess implements Action {
  readonly type = LOAD_SUCCESS;
  constructor(public payload: Account) {}
}

export class FindSuccess implements Action {
  readonly type = FIND_SUCCESS;
  constructor(public payload: Account[]) {}
}

export class CreateSuccess implements Action {
  readonly type = CREATE_SUCCESS;
  constructor(public payload: Account) {}
}

export class CreateSuccessLoaded implements Action {
  readonly type = CREATE_SUCCESS_LOADED;
  constructor(public payload: Account) {}
}

export class UpdateSuccess implements Action {
  readonly type = UPDATE_SUCCESS;
  constructor(public payload: Account) {}
}

export class UpdateSuccessLoaded implements Action {
  readonly type = UPDATE_SUCCESS_LOADED;
  constructor(public payload: Account) {}
}

export class DeleteSuccess implements Action {
  readonly type = DELETE_SUCCESS;
  constructor(public payload: number) {}
}

export type All =
  | SetEditID
  | LoadAll
  | Load
  | Find
  | Create
  | Update
  | Delete
  | LoadAllSuccess
  | LoadSuccess
  | UpdateSuccess
  | UpdateSuccessLoaded
  | CreateSuccess
  | CreateSuccessLoaded
  | FindSuccess
  | DeleteSuccess;
