import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class ZziService {
  private url: string;

  constructor(
    private http: HttpClient
  ) {
    this.url = environment.api_base_url + '/zzi';
  }

  getZzi() {
    return this.http.get(this.url+ "/search/getZZI").map((data: any) => { return data._embedded.zzi[0] as Zzi; });
  }

  createZzi(zzi:Zzi) {
    return this.http.post(this.url, zzi);
  }

  updateZzi(zzi:Zzi) {
    console.log("updateZzi: " + this.url + "/" + zzi.id);
    console.log(zzi);
    return this.http.patch(this.url + "/" + zzi.id, zzi);
  }
}

export interface Zzi {
  id: number;
  rate: number;
  bank: string;
  banknumber: string;
  btwnumber: string;
  kvknumber: string;
  praktijk: string;

  _links: any;
}
