import {Injectable, Injector} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http'
import {Observable} from 'rxjs/Observable';
import {HttpError} from '@app-shared-root/interceptor/http.error';
import {ToasterService} from 'angular2-toaster';
import 'rxjs/add/operator/do';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
// Regular dep. injection doesn't work in HttpInterceptor due to a framework issue (as of angular@5.2.9),
// use Injector directly (don't forget to add @Injectable() decorator to class).
  constructor(private _injector: Injector) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const logFormat = 'background: maroon; color: white';

    return next.handle(req)
      .do(event => {
      }, exception => {
        const toasterService = this._injector.get(ToasterService);
        if (exception instanceof HttpErrorResponse) {
          switch (exception.status) {

            case HttpError.BadRequest:
              console.error('%c Bad Request 400', logFormat);
              break;

            case HttpError.Unauthorized:
              console.error('%c Unauthorized 401', logFormat);
              window.location.href = '/login' + window.location.hash;
              break;

            case HttpError.NotFound:
              //show error toast message
              console.error('%c Not Found 404', logFormat);
              toasterService.pop('Fout',
                exception.error && (exception.error.message ? exception.error.message :
                  exception.statusText)
              );
              break;

            case HttpError.TimeOut:
              // Handled in AnalyticsExceptionHandler
              console.error('%c TimeOut 408', logFormat);
              break;

            case HttpError.Forbidden:
              console.error('%c Forbidden 403', logFormat);
              break;

            case HttpError.InternalServerError:
              console.error('%c bad 500', logFormat);
              toasterService.pop('Fout',
                exception.error && (exception.error.message ? exception.error.message :
                  exception.statusText)
              );
              break;
          }
        }
      });
  }
}
