import {BrowserAnimationsModule} from '@angular/platform-browser/animations'; // this is needed!
import {NgModule} from '@angular/core';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import {AppComponent} from './app.component';

import {CoreModule} from './core/core.module';
import {LayoutModule} from './layout/layout.module';
import {SharedModule} from './shared/shared.module';
import {RoutesModule} from './routes/routes.module';

import {CanActivateViaAuthGuard} from './guard/can-activate-via-auth.guard';
import {LoginService} from './services/login.service';
import {HttpClient, HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {JwtModule} from '@auth0/angular-jwt';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';

import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import { nlLocale } from 'ngx-bootstrap/locale';
import localeNl from '@angular/common/locales/nl';
import {registerLocaleData} from "@angular/common";
import {reducers} from "@app-root-store";
import {ErrorInterceptor} from '@app-shared-root/interceptor/error.interceptor';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';


export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function getAccessToken() {
  return localStorage.getItem('token');
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    HttpClientModule,
    BrowserAnimationsModule, // required for ng2-tag-input
    CoreModule,
    LayoutModule,
    SharedModule.forRoot(),
    RoutesModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: (getAccessToken),
        allowedDomains: ['localhost:8099', 'localhost:4200', 'idb.engbers.biz', 'idb.step.nl', 'step-idb.nl', 'www.step-idb.nl']
      }
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    StoreModule.forRoot(reducers, {}),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      maxAge: 25
    })
  ],
  providers: [CanActivateViaAuthGuard, LoginService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    }],
  bootstrap: [AppComponent]
})

export class AppModule {

  constructor(
    private localeService: BsLocaleService) {

    defineLocale('nl', nlLocale);
    this.localeService.use('nl');
    registerLocaleData(localeNl);
  }
}
