import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../../core/settings/settings.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { LoginService } from '../../../services/login.service';
import {  Router} from '@angular/router';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  form: FormGroup;
  wrongCredentials: boolean;
  sendRequest: boolean;

  constructor(
    public settings: SettingsService,
    fb: FormBuilder,
    private loginService: LoginService,
    private router: Router
  ) {
    this.form = fb.group({
      'username': [null, Validators.compose([Validators.required, CustomValidators.email])],
      'password': [null, Validators.required]
    });


  }

  get username() { return this.form.get('username'); }
  get password() { return this.form.get('password'); }

  submitForm($ev, value: any) {
    // $ev.preventDefault();

    this.sendRequest = !this.sendRequest;

    // for (const c in this.form.controls) {
    //   this.form.controls[c].markAsTouched();
    // }

    // if (this.form.valid) {
      this.loginService.obtainAccessToken(value)
        .subscribe(res => {
          if ( this.loginService.referrer == null ) {
            this.router.navigate(['/']);
          } else {
            this.router.navigate([this.loginService.referrer]);
          }
        },
            err => {
              this.wrongCredentials = true;
              this.sendRequest = !this.sendRequest;
        });
    // }
  }

  ngOnInit() {

  }
}
