import * as actions from './actions';
import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {Company} from '@app-routes/company/company.model';
import {unescapeIdentifier} from "@angular/compiler";

export const adapter = createEntityAdapter<Company>();

export interface State extends EntityState<Company> {
  editId: number;
}

export const INIT_STATE: State = adapter.getInitialState({
  editId: undefined,
  searchQuery: undefined
});

export function Reducer(state: State = INIT_STATE, {type, payload}: actions.All) {
  switch (type) {

    case actions.SET_EDIT_ID : {
      return {...state, editId: payload};
    }

    case actions.SEARCH_ALL : {
      return {...state, searchQuery:payload }
    }

    case actions.LOAD_ALL_SUCCESS : {
      return {...state, ...adapter.addAll(payload as Company[], state)};
    }

    case actions.SEARCH_ALL_SUCCESS : {
      return {...state, ...adapter.addAll(payload as Company[], state)};
    }

    case actions.LOAD_SUCCESS : {
      return {...state, ...adapter.addOne(payload as Company, state)};
    }

    case actions.CREATE_SUCCESS : {
      return {...state, ...adapter.addOne(payload as Company, state)};
    }

    case actions.UPDATE_SUCCESS : {
      return {
        ...state,
        ...adapter.updateOne({
          id: payload.id,
          changes: payload
        }, state)
      };
    }

    case actions.DELETE_SUCCESS : {
      return {...state, ...adapter.removeOne(payload, state)};
    }

    default:
      return state;
  }
}

export const getCurrentEditId = (state: State) => state.editId;
