import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Entry} from '../../entry/entry.model';
import {Person, PersonImpl} from '@app-routes/person/person.model';
import {PersonService} from '@app-services/person.service';
import {Company} from '@app-routes/company/company.model';
import {AuthService} from '@app-services/auth.service';
import {CompanyService} from '@app-services/company.service';
import * as actions from '@app-entries-store/actions';
import {now} from 'moment';
import {Store} from '@ngrx/store';
import * as fromEntries from '@app-entries-store';
import {Router} from '@angular/router';
import {TypeaheadModel, typeAheadValidator} from '@app-shared-root/typeahead/typeahead.model';
import {Observable} from 'rxjs';
import {Address} from '@app-shared-models/address';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/public_api';

@Component({
  selector: 'app-company-entry',
  templateUrl: './company-entry.component.html',
  styleUrls: ['./company-entry.component.scss']
})
export class CompanyEntryComponent implements OnInit {
  entry: Entry;
  personForm: FormGroup;
  problemForm: FormGroup;
  entryForm: FormGroup;

  employees: Person[];
  companies: Company[];

  selectedPerson: Person;
  selectedCompanyId: number;

  step = 0;

  personSelect:boolean = null;

  arboModel:TypeaheadModel;
  arbodiensten: Company[];
  arbocontacten: Person[];


  selectedDoctor: Person;

  constructor(
    private fb: FormBuilder,
    private personService: PersonService,
    private companyService: CompanyService,
    private authService: AuthService,
    public bsModalRef: BsModalRef,
    private router: Router,
    private store: Store<fromEntries.State>) {

    this.arboModel = new TypeaheadModel();

    this.personForm = fb.group({
      'id': [],
      'person': fb.group({
        'firstnames': [],
        'initials': [null, Validators.required],
        'lastname': [null, Validators.required],
        'sex': [null, Validators.required],
        'city': [null, Validators.required],
        'phone': [null, Validators.required],
        'geboortejaar': [],
        'dossiernummer': [],
        'arbodienst': [null, typeAheadValidator(this.arboModel)],
        'doctor': [null],
        'email': [null],
        'receiveReport': [null]
        },),
    });
    this.problemForm = fb.group({
      'problem': [null, Validators.required],
      'bijzonderheden': []
    });
    this.entryForm = fb.group({
      'aanvrager': [null, Validators.required],
      'poNummer': [],
      'offerte': []
    });

    //TODO: Indien verslag naar werknemer dan email adres invullen
  }

  ngOnInit() {
    this.getEmployees();
    this.getCompanies();
    this.getAccountAanvrager();
    this.getArboDiensten();
  }

  getEmployees() {
    this.personService.getAccountPersons().subscribe(res => this.employees = res);
  }
  getCompanies() {
    this.companyService.getAccountCompanies().subscribe(res => this.companies = res);
  }
  getAccountAanvrager() {
    this.personService.getCurrentPerson().subscribe(res => this.aanvrager.setValue(res.fullName));
  }
  getArboDiensten() {
    this.arbodiensten = Observable.create((observer:any) => {
      this.companyService.searchNameExtend(  this.arbodienst.value, "ARBODIENST")
        .subscribe((result : any ) => {
          if ( result) {
            observer.next(result);
          }
        });
    });
  }

  get firstnames() { return this.personForm.get('person.firstnames'); };
  get initials() { return this.personForm.get('person.initials'); };
  get lastname() { return this.personForm.get('person.lastname'); };
  get sex() { return this.personForm.get('person.sex'); };
  get city() { return this.personForm.get('person.city'); };
  get phone() { return this.personForm.get('person.phone'); };
  get geboortejaar() { return this.personForm.get('person.geboortejaar'); };
  get dossiernummer() { return this.personForm.get('person.dossiernummer'); };
  get arbodienst() { return this.personForm.get('person.arbodienst'); };
  get doctor() { return this.personForm.get('person.doctor'); };
  get email() { return this.personForm.get('person.email'); };
  get receiveReport() { return this.personForm.get('person.receiveReport'); };



  get problem() { return this.problemForm.get('problem'); };
  get bijzonderheden() { return this.problemForm.get('bijzonderheden'); };

  get aanvrager() { return this.entryForm.get('aanvrager'); };
  get offerte() { return this.entryForm.get('offerte'); };

  onSelectedPerson(e: TypeaheadMatch) {
    if ( this.selectedPerson && this.selectedPerson._links ) {
      console.log(this.selectedPerson);
      this.selectedCompanyId = this.selectedPerson.company.id;
      this.firstnames.setValue(this.selectedPerson.firstnames);
      this.initials.setValue(this.selectedPerson.initials);
      this.lastname.setValue(this.selectedPerson.lastname);
      this.sex.setValue(this.selectedPerson.sex);
      this.city.setValue(this.selectedPerson.address.city);
      this.phone.setValue(this.selectedPerson.phone);
      this.geboortejaar.setValue(this.selectedPerson.dobYear);
    }
  }

  setPersonSelect(personSelect: boolean) {
    this.personSelect = personSelect;
    this.selectedPerson = null
    this.firstnames.setValue('');
    this.initials.setValue('');
    this.lastname.setValue('');
  }


  onSelectArbo(e: TypeaheadMatch) {
    this.arboModel.setSelectedRef(e.item._links.self.href, this.arbodienst.value);
    this.arbodienst.updateValueAndValidity();
    this.getArbocontacts(e.item.id);
  }

  getArbocontacts(id) {
    this.personService.getPersonByCompany(id).subscribe(res => this.arbocontacten = res);
  }

  onSelectedDoctor(e: TypeaheadMatch) {
    if ( this.selectedDoctor && this.selectedDoctor._links ) {
      this.doctor.setValue(this.selectedDoctor._links.self.href);
    }
  }

  receiveReportChange() {
    if ( !this.receiveReport.value ) {
      this.email.setValue('');
    }
  }

  sendEntry() {
    if( this.selectedPerson == null ) {
      this.selectedPerson = new PersonImpl();
    }
    if ( this.selectedPerson.address == null ) {
      this.selectedPerson.address =  {
        'id': null,
        'city': '',
        'street': '',
        'nr': '',
        'postalcode': ''
      }
    }
    this.selectedPerson.firstnames = this.firstnames.value;
    this.selectedPerson.initials = this.initials.value;
    this.selectedPerson.lastname = this.lastname.value;
    this.selectedPerson.sex = this.sex.value;
    this.selectedPerson.address.city = this.city.value;
    this.selectedPerson.phone = this.phone.value;
    this.selectedPerson.email = this.email.value;
    this.selectedPerson.receiveReport = this.receiveReport.value;
    this.selectedPerson.dobYear = parseInt(this.geboortejaar.value);
    const selectedCompany = this.companies.filter(value => value.id === this.selectedCompanyId)[0];
    this.selectedPerson.company = selectedCompany._links.self.href;

    if ( this.selectedPerson.id ) {
      this.personService.update(this.selectedPerson).subscribe((data: any) => {
        const persistedPerson: Person = data as Person;
        this.submitEntry(persistedPerson, selectedCompany);
      });
    }
    else {
      this.personService.create(this.selectedPerson).subscribe((data: any) => {
        const persistedPerson: Person = data as Person;
        this.submitEntry(persistedPerson, selectedCompany);
      });
    }
  }

  submitEntry(person: Person, company: Company) {
    const entry:Entry = {
      'id': null,
      'person': person._links.self.href,
      'company': company._links.self.href,
      'aanvrager': this.aanvrager.value,
      'entryDate': new Date(now()),
      'verified': false,
      'problem': this.problem.value,
      'bijzonderheden': this.bijzonderheden.value,
      'bestelnummer': this.dossiernummer.value,
      'doctorReceiveReport': 'true',
      'personReceiveReport': this.receiveReport.value,
      'submitter' : localStorage.getItem('username')
    };
    entry['arbodienst'] = this.arboModel.getSelectedRef();
    entry['doctor'] = this.doctor.value;
    entry['offerteNaIntake'] = this.offerte.value;

    console.log(entry);
    this.store.dispatch(new actions.Create(entry));

    this.step++;
  }

  openEntries() {
    this.bsModalRef.hide();
    this.router.navigate(['/entries/c-entries']);
  }
}
