import { Injectable } from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';

@Injectable()
export class AuthService {

  constructor(private jwtHelper: JwtHelperService) { }

  isLoggedIn() {
    const token = localStorage.getItem('token');

    if (!token)
      return false;

    if (this.jwtHelper.isTokenExpired()) {
      console.warn('token is expired');
      localStorage.removeItem('token');
      localStorage.removeItem('2fap');
      return false;
    }
    return !this.jwtHelper.isTokenExpired();
  }

  get currentUser() {
    const token = localStorage.getItem('token');

    if (!token)
      return null;

    return this.jwtHelper.decodeToken(token);
  }

  get currentRoles(): string[] {
    const roles: string[] = this.currentUser.authorities;

    return roles;
  }

  public isTwoFaRequired(): boolean {
    console.log(localStorage.getItem('2fap'));
    if ( localStorage.getItem('2fap') === 'passed' ) {
      return false;
    }
    return this.isTwoFaActive();
  }

  public isTwoFaActive(): boolean {
    return this.currentRoles.indexOf('ROLE_TWO_FACTOR_AUTHENTICATED') > -1;
  }

  get isStep() {
    const roles = this.currentUser.authorities;
    return this.isAdmin || roles.indexOf('ROLE_STEP') > -1;
  }

  get isInstructor() {
    const roles = this.currentUser.authorities;
    return roles.indexOf('ROLE_INSTRUCTOR') > -1;
  }

  get isCompany() {
    const roles = this.currentUser.authorities;
    return roles.indexOf('ROLE_COMPANY') > -1;
  }

  get isAdmin() {
    const roles = this.currentUser.authorities;
    return roles.indexOf('ROLE_ADMIN') > -1;
  }

}
