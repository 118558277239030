import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {DataService} from './data.service';
import {AuthService} from '@app-services/auth.service';

@Injectable()
export class EntryService extends DataService {

  constructor(http: HttpClient, authService: AuthService) {
    const entryUrl = environment.api_base_url + '/entries';
    super(entryUrl, http, authService);
  }

  getAllMapped() {
    if (this.authService.isCompany) {
      return this.getAll("company/open").map((data: any) => {
        if ( data && data._embedded ) {
          return data._embedded.entries as any[];
        }
        return [];
      });
    } else {
      return this.getAll("search/findByIndividualIsNull").map((data: any) => {
        return data._embedded.entries as any[];
      });
     }
  }
  convertEntry(id) {
    const params = 'convert';
    return this.get(id, params);
  }

}
