import * as fromCompanies from './reducers';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromRoot from '@app-root-store';

export interface CompanyState {
  companies: fromCompanies.State;
}

export interface State extends fromRoot.State {
  companies: CompanyState;
}

export const reducers = {
  companies: fromCompanies.Reducer
};

export const getRootState = createFeatureSelector<CompanyState>('companies');

export const getState = createSelector(
  getRootState,
  (state: CompanyState) => state.companies
);

export const getSelectedId = createSelector(
  getState,
  fromCompanies.getCurrentEditId
);

export const {
  selectAll: getAll,
  selectEntities: getEntities
} = fromCompanies.adapter.getSelectors(getState);

export const getCurrent = createSelector(
  getEntities,
  getSelectedId,
  (entities, id) => id && entities[id]
);
