import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import { FormBuilder, FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-table-search',
  templateUrl: './table-search.component.html',
  styleUrls: ['./table-search.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TableSearchComponent implements OnInit {
  @Input() config: TsConfig;
  @Output() doSearch: EventEmitter<any> = new EventEmitter;
  searchMore: boolean = false;
  searchBy: TsConfigOption;
  advancedSearch: boolean = false;
  form: FormGroup;

  constructor(private fb: FormBuilder) {
    this.form = fb.group({});
  }

  ngOnInit() {
    this.searchBy = this.config.defaultOption;
    this.form.addControl(this.searchBy.field, new FormControl());
  }

  onSearchBy(newValue) {
    this.advancedSearch = (newValue === 'advanced') ? true : false;

    // remove control keys
    Object.keys(this.form.controls).forEach(key => {
      this.form.removeControl(key);
    });

    if (newValue === 'advanced') {
      const options = this.config.options;
      options.forEach(option => {
        if (!option.exclude) this.form.addControl(option.field, new FormControl());
      });
    } else {
      this.form.addControl(newValue.field, new FormControl());
    }
    this.searchBy = newValue;
  }

  onSearch() {
    const searchQuery = this.form.value;
    this.doSearch.emit({ searchQuery });
  }

  onManualSearch() {
    const searchQuery = this.form.value;
    this.doSearch.emit({ searchQuery, searchMore: true });
  }
}

export interface TsConfig {
  placeholder: string;
  defaultOption: TsConfigOption;
  options: TsConfigOption[];
  advanced: boolean;
}

export interface TsConfigOption {
  field: string;
  translate: string;
  exclude?: boolean;
}
