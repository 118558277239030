import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Person} from '../person.model';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';
import {Store} from '@ngrx/store';

import * as actions from '@app-persons-store/actions';
import * as fromPersons from '@app-persons-store';
import {CompanyService} from '@app-services/company.service';
import {environment} from '../../../../environments/environment';
import {Company} from '@app-routes/company/company.model';
import {Observable} from 'rxjs/Observable';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/public_api';

@Component({
  selector: 'app-add-person',
  templateUrl: './add-person.component.html',
  styleUrls: ['./add-person.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddPersonComponent implements OnInit {
  person: Person;
  form: FormGroup;
  bsConfig: Partial<BsDatepickerConfig>;

  selectedCompany: any;
  companies: Observable<Company>;
  // companies;

  maxDate = moment().toDate();

  dobYearOnly: boolean = false;


  constructor(
    fb: FormBuilder,
    public bsModalRef: BsModalRef,
    private translate: TranslateService,
    private companyService: CompanyService,
    private personStore: Store<fromPersons.State>
  ) {
    this.form = fb.group({
      'id': [null],
      'initials': [null, Validators.required],
      'firstnames': [null],
      'infix': [],
      'lastname': [null, Validators.required],
      'sex': [null, Validators.required],
      'dob': [],
      'dobYear': [],
      'address': fb.group({
        'street': [],
        'nr': [],
        'postalcode': [],
        'city': [null, Validators.required]
      }),
      'phone': [],
      'mobile': [],
      'sofinumber': [],
      'function': [],
      'dienstverband': [],
      'email': [],
      'receiveReport': [],
      'insurernumber': [],
      'company': [null, [Validators.required, Validators.minLength(3)]]
    });

    // this.companyService.getAllMapped().subscribe(data => this.companies = data);

    this.companies = Observable.create((observer: any) => {
      this.companyService.searchAll(  this.selectedCompany )
        .subscribe((result: any ) => {
          if ( result) {
            observer.next(result);
          }
        });
    });
  }

  ngOnInit() {
    this.bsConfig = Object.assign({}, this.bsConfig, { dateInputFormat: 'DD-MM-YYYY'});

    if ( fromPersons.getCurrent ) {
      this.personStore.select(fromPersons.getCurrent)
        .subscribe(formValue => {
          if (formValue) {
            this.setPerson(formValue);
          }
        });
    }
  }

  setPerson(person:Person) {
    this.form.patchValue(person);
    this.person = person;
    this.selectedCompany = person.company.name;
    if ( this.person.dob == null && this.person.dobYear > 0 ) {
      this.dobYearOnly = true;
    }
  }

  get initials() { return this.form.get('initials'); };
  get firstnames() { return this.form.get('firstnames'); };
  get lastname() { return this.form.get('lastname'); };
  get sex() { return this.form.get('sex'); };
  get dob() { return this.form.get('dob'); };
  get city() { return this.form.get('address.city'); };
  get dobYear() { return this.form.get('dobYear'); };
  get company() { return this.form.get('company'); };

  onSelectCompany(e: TypeaheadMatch) {
    this.selectedCompany = e.item.name;
    this.company.setValue(e.item);
    this.onCheckValidation();
  }

  public setCompany(company: Company) {
    console.log('Person set company');
    console.log(company);
    this.selectedCompany = company.name;
    this.company.setValue(company);
    this.onCheckValidation();
  }

  onCheckValidation() {
    const company = this.company.value;
    if (company && this.selectedCompany === company.name) {
      this.company.setErrors(null);
    } else {
      this.company.setErrors({ 'INVALID': true });
      this.company.setValue(null);
    }
  }

  onSubmit(form: FormGroup, account?: boolean) {
    const company: Company = form.value.company;

    if (company.name === this.selectedCompany) {
      this.company.setValue(environment.api_base_url + '/companies/' + company.id);
    }

    let person: Person = form.value;

    if ( this.dob.value) {
      person.dob = moment(this.dob.value, 'DD-MM-YYYY').utc(true).toDate();
    }
    console.log('submit person');
    console.log(this.person);

    if (this.person) {
      this.personStore.dispatch(new actions.Update(person));
    } else {
      console.log('create');
      this.personStore.dispatch(new actions.Create(person));
    }

    this.bsModalRef.hide();
  }

  toggleDob() {
    console.log('toggle dob');
    this.dobYearOnly = !this.dobYearOnly;
  }
}
