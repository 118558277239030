import { Component, OnInit } from '@angular/core';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {SettingsService} from '../../../core/settings/settings.service';
import {environment} from '../../../../environments/environment';

import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {LoginService} from '@app-services/login.service';

@Component({
  selector: 'app-twofa',
  templateUrl: './twofa.component.html',
  styleUrls: ['./twofa.component.scss']
})
export class TwofaComponent implements OnInit {

  form: FormGroup;

  wrong2fa: boolean;
  sendRequest: boolean;

  constructor(
    private router: Router,
    private loginService: LoginService,
    public settings: SettingsService,
    private fb: FormBuilder,
    public httpClient: HttpClient) { }

  ngOnInit() {
    this.form = this.fb.group({
      'twoFa': [null, Validators.compose([Validators.required])],
    });

  }

  get twoFa() { return this.form.get('twoFa'); }

  submitForm($ev, value: any): void {
    $ev.preventDefault();

    this.sendRequest = true;

    for (const c in this.form.controls) {
      this.form.controls[c].markAsTouched();
    }

    if (this.form.valid) {
      this.httpClient.get(environment.api_base_url + '/2fa?verificationCode='+ this.twoFa.value).subscribe(
        (response: any) => {
          console.log(response);
          this.sendRequest = false;
          if ( response.response === 'validated' ) {
            localStorage.setItem('2fap', 'passed');
            if ( this.loginService.referrer == null ) {
              this.router.navigate(['/']);
            } else {
              this.router.navigate([this.loginService.referrer]);
            }
          }
        }, (error) => {
          this.sendRequest = false;
          this.twoFa.setErrors({'code_error': true});
        });

    }
  }
}
