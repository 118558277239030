import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {DataService} from './data.service';
import {Declaration} from "@app-routes/declaration/declaration.model";

@Injectable()
export class DeclarationService extends DataService {
  constructor(http: HttpClient) {
    const declarationUrl = environment.api_base_url + '/declarations';
    super(declarationUrl, http);
  }

  getAllMapped() {
    const projection = 'search/findDeclarations';
    return this.getAll(projection).map((data: any) => { return data._embedded.declarations as Declaration[]; });
  }
}
