import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from "rxjs/Observable";

@Injectable()
export class ReportService  {
  private reportUrl:string;

  constructor(
    private http: HttpClient) {
    this.reportUrl = environment.api_base_url + '/individuals/report/';
  }

  public getCompanyReportFile(id:number, reportType:string, contactType:string):Observable<Blob> {
    return this.http.get(this.reportUrl + id + "/"+ reportType + "?contacttype="+contactType, {responseType: "blob"});
  }


  public setReportReadySTEP(id:number, reportType:string, sendmail:boolean):Observable<any> {
    return this.http.get(this.reportUrl + id + "/reportreadystep/"+ reportType + "?sendmail=" + sendmail);
  }

  public setReportReadyCompany(id:number, reportType:string, sendmail:boolean):Observable<any> {
    return this.http.get(this.reportUrl + id + "/reportreadycompany/"+ reportType + "?sendmail=" + sendmail);
  }

  public getDeclarationReport(id:number) {
    return this.http.get(environment.api_base_url + "/declarations/report/" + id, {responseType: "blob"});
  }
}
