import { Pipe, PipeTransform } from '@angular/core';
import {TsConfigOption} from '../components/table-search/table-search.component';

@Pipe({
  name: 'excludeFilter'
})
export class ExcludeFilterPipe implements PipeTransform {

  transform(items: any[], key: string, exclude?: boolean): any {
    if (!items || !key) {
      return items;
    }

    return items.filter(item => item[key] !== true);
  }

}
