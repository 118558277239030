import {AbstractControl} from '@angular/forms';

export class PasswordValidators {
  static passwordsShouldMatch(control: AbstractControl) {
    const password = control.get('password').value;
    const confirmPassword = control.get('confirmPassword').value;

    if (password !== confirmPassword)
      return { passwordsShouldMatch: true };

    return null;
  }
}
