import { LayoutComponent } from '../layout/layout.component';
import {LoginComponent} from './auth/login/login.component';
import {CanActivateViaAuthGuard} from '../guard/can-activate-via-auth.guard';
import {ForgotPasswordComponent} from './auth/forgot-password/forgot-password.component';
import {ResetPasswordComponent} from './auth/reset-password/reset-password.component';
import {ActivateComponent} from '@app-routes/auth/activate/activate.component';
import {TwofaComponent} from '@app-routes/auth/twofa/twofa.component';

export const routes = [

    {
        path: '',
        component: LayoutComponent,
        canActivate: [CanActivateViaAuthGuard],
        children: [
            { path: '', redirectTo: 'home', pathMatch: 'full' },
            { path: 'home', loadChildren: './home/home.module#HomeModule' },
            { path: 'account', loadChildren: './account/account.module#AccountModule' },
            { path: 'accounts', loadChildren: './account/account.module#AccountModule' },
            { path: 'persons', loadChildren: './person/person.module#PersonModule' },
            { path: 'entries', loadChildren: './entry/entry.module#EntryModule' },
            { path: 'individual', loadChildren: './individual/individual.module#IndividualModule' },
            { path: 'consults', loadChildren: './consult/consult.module#ConsultModule' },
            { path: 'companies', loadChildren: './company/company.module#CompanyModule' },
            { path: 'fields', loadChildren: './fields/fields.module#FieldsModule' },
            { path: 'indextype', loadChildren: './indextype/indextype.module#IndextypeModule' },
            { path: 'zzi', loadChildren: './zzi/zzi.module#ZziModule' },
            { path: 'declaration', loadChildren: './declaration/declaration.module#DeclarationModule' },
            { path: 'bi', loadChildren: './bi/bi.module#BiModule' },
            { path: 'pib', loadChildren: './pib/pib.module#PibModule' },
            { path: 'pib-individual', loadChildren: './pib-individual/pib-individual.module#PibIndividualModule' },
            { path: 'webshop', loadChildren: './webshop/webshop.module#WebshopModule' }
        ]
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: '2fa',
        component: TwofaComponent
    },
    {
        path: 'resetpassword',
        component: ForgotPasswordComponent,
    },
    {
        path: 'resetpassword/:id',
        component: ResetPasswordComponent,
    },
    {
        path: 'activate/:id',
        component: ActivateComponent,
    },


    // Not found
    { path: '**', redirectTo: 'home' }

];
