import { Injectable } from '@angular/core';
import {MenuItem, MenuitemService} from "@app-services/menuitem.service";
import {Subject} from 'rxjs/Subject';

@Injectable()
export class MenuService {

    private menuItemsSource = new Subject<MenuItem>();
    private alertSource = new Subject<string[]>();

    menuItems$ = this.menuItemsSource.asObservable();
    alerts$ = this.alertSource.asObservable();

    constructor(private menuitemService: MenuitemService) {
    }

    addMenu(items: MenuItem[]) {
      items.forEach((item) => {
        this.menuItemsSource.next(item);
      });
    }

    getMenu() {
        return this.menuItemsSource;
    }

    loadMenuItems() {
      console.log('Load Menu');
      this.menuitemService.getMenuItems().forEach(menuItems => this.addMenu(menuItems));
    }

    doAlert( alert:string, link:string) {
      this.alertSource.next([alert, link]);
    }

}
