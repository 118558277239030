import { Component, OnInit } from '@angular/core';
import {LoginService} from '../../../services/login.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  emailSent: boolean;
  accountNotFound: boolean;
  sendRequest: boolean;

  constructor(
    private loginService: LoginService
  ) {
    this.emailSent = false;
  }

  ngOnInit() {
  }

  onSubmit(form) {
    this.sendRequest = !this.sendRequest;

    this.loginService.forgotPassword(form)
      .subscribe(() => {
          this.emailSent = !this.emailSent;
        }, () => {
          this.emailSent = !this.emailSent;
        });
  }

}
