import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {DataService} from './data.service';
import {Person, PersonSimple} from 'app/routes/person/person.model';
import {Account} from 'app/routes/account/account.model';
import {Role} from "@app-services/role.service";

@Injectable()
export class PersonService extends DataService {
  constructor(http: HttpClient) {
    const personsUrl = environment.api_base_url + '/persons';
    super(personsUrl, http);
  }

  getAllMapped() {
    const projection = '?projection=personProjection';
    return this.getAll(projection).map((data: any) => { return data._embedded.persons as Person[]; });
  }

  getPersonByCompany(id) {
    const params = `search/findByCompany?company_id=${id}`;
    return this.getAll(params).map((data: any) => { return data._embedded.persons as Person[] ; });
  }

  getInstructors() {
    const params = 'search/instructors';
    return this.getAll(params).map((data: any) => { return data._embedded.persons as Person[] ; });
  }

  getPersonAccount(personId:number) {
    const params = '/account';
    return this.get(personId, params).map((data: any) => { return data as Account ; });
  }

  searchAll(searchQuery: any) {
    let searchParams = new URLSearchParams();
    for(let key in searchQuery){
      searchParams.set(key, searchQuery[key])
    }
    const params = 'search/all?' + searchParams.toString();
    return this.getAll(params).map((data: any) => { if ( data && data._embedded ) { return data._embedded.persons as Person[]; }} );
  }

  getAccountPersons() {
    return this.getAll('account').map( (data:any) => { if ( data && data._embedded) { return data._embedded.persons as Person[] ; }} );
  }

  getCurrentPerson() {
    return this.getAll('current').map( (data:any) => { if ( data ) { return data as Person ; }} );
  }
}
