import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable()
export class IconsultsService {
  url: string;
  headers: any;

  constructor(
    private http: HttpClient
  ) {
    this.url = environment.api_base_url + '/iconsults';
    // TODO: SPRING missing Access-Control-Allow-Headers: contenttype in preflight
    this.headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
  }

  create(resource) {
    return this.http.post(this.url, resource, { headers: this.headers});
  }

  update(resource) {
    return this.http.patch(this.url + '/' + resource.id, JSON.stringify(resource), { headers: this.headers});
  }

  delete(id) {
    return this.http.delete(this.url + '/' + id, { headers: this.headers});
  }
}
