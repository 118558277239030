import * as fromPersons from './reducers';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromRoot from '@app-root-store';

export interface PersonState {
  persons: fromPersons.State;
}

export interface State extends fromRoot.State {
  persons: PersonState;
}

export const reducers = {
  persons: fromPersons.Reducer
};

export const getRootState = createFeatureSelector<PersonState>('persons');

export const getState = createSelector(
  getRootState,
  (state: PersonState) => state.persons
);

export const getSelectedPersonId = createSelector(
  getState,
  fromPersons.getCurrentEditId
);

export const {
  selectAll: getAll,
  selectEntities: getEntities
} = fromPersons.adapter.getSelectors(getState);

export const getCurrent = createSelector(
  getEntities,
  getSelectedPersonId,
  (entities, id) => id && entities[id]
);
