import {Injectable, Injector} from '@angular/core';
import {Pib} from '@app-routes/pib/pib.model';
import {DataService} from '@app-services/data.service';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Company} from '@app-routes/company/company.model';
import {Piblesson} from '@app-routes/pib/piblesson.model';

@Injectable()
export class PiblessonService extends DataService {

  constructor(http: HttpClient) {
    const pibUrl = environment.api_base_url + '/piblesson';
    super(pibUrl, http);
  }

  getAllPibLessons() {
    const projection = '?projection=pibLessonTableProjection';
    return this.getAll(projection).map((data: any) => { return data._embedded.piblesson as Piblesson[]; });
  }

  getPiblessonReportFile(personId: number) {
    return this.http.get(this.url + "/" + personId + "/report", {responseType: "blob"});
  }

}
