import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class MenuitemService {
  private url: string;

  constructor(
    private http: HttpClient
  ) {
    this.url = environment.api_base_url + '/menu';
  }

  getMenuItems() {
    return this.http.get(this.url).map((data: any) => { return data as MenuItem[]; });
  }
}

export interface MenuItem {
  text: string;
  heading?: boolean;
  link?: string;     // internal route links
  elink?: string;    // used only for external links
  target?: string;   // anchor target="_blank|_self|_parent|_top|framename"
  icon?: string;
  alert?: string;
  submenu?: Array<any>;
}
