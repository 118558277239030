import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {DataService} from './data.service';
import {HttpClient} from '@angular/common/http';
import {Account} from '../routes/account/account.model';
import {Role} from "@app-services/role.service";
import {Company} from '@app-routes/company/company.model';

@Injectable()
export class AccountService extends DataService {
  constructor(http: HttpClient) {
    const accountsUrl = environment.api_base_url + '/accounts';
    super(accountsUrl, http);
  }

  getAllMapped() {
    return this.getAll('search/all').map((data: any) => { if ( data ) return data as Account[]; });
  }

  findAccount(email:string) {
    let params:string = "search/findByLoginname?loginname=" + email;
    return this.getAll(params).map((data: any) => { if ( data ) return data as Account[]; });
  }

  getAccountRoles(accountId:number) {
    const params = 'roles';
    return this.get(accountId, params).map((data: any) => { return data as Role[] ; });
  }

  getCompanies(account: Account) {
    var params =  'companies';
    return this.get(account.id, params).map( ( data: any) => { if ( data && data._embedded) return data._embedded.companies as Company[]} );
  }
}
