import { Injectable } from '@angular/core';
import {DataService} from '@app-services/data.service';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Field} from '@app-shared-root/models/field';
@Injectable()
export class FieldsService extends DataService {

  constructor(http: HttpClient) {
    const fieldsUrl = environment.api_base_url + '/fields';
    super(fieldsUrl, http);
  }

  getFieldByType(type) {
    const params = `search/findByFieldNameEquals?fieldName=${type}`;
    return this.getAll(params).map((data: any) => { return data._embedded.fields as Field[]; });
  }

  getAllMapped() {
    console.log("FIELDS get all mapped");
    return this.getAll().map((data: any) => { return data._embedded.fields as Field[]; });
  }
}
