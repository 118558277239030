import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class RoleService {
  private url: string;

  constructor(
    private http: HttpClient
  ) {
    this.url = environment.api_base_url + '/roles';
  }

  getRoles() {
    return this.http.get(this.url).map((data: any) => { return data._embedded.roles as Role[]; });
  }
}

export interface Role {
  id: number;
  description: string;
  value: string;
  _links: any;
}
