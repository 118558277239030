
import {Action} from '@ngrx/store';
import {Company} from '@app-routes/company/company.model';

export const LOAD_ALL = '[companies] LOAD ALL';
export const LOAD_ALL_SUCCESS = '[companies] LOAD SUCCESS';

export const SEARCH_ALL = '[companies] SEARCH ALL';
export const SEARCH_ALL_SUCCESS = '[companies] SEARCH SUCCESS';

export const LOAD = '[company] LOAD';
export const LOAD_SUCCESS = '[company] LOAD SUCCESS';

export const CREATE = '[company] CREATE';
export const CREATE_SUCCESS = '[company] CREATE SUCCESS';

export const UPDATE = '[company] UPDATE';
export const UPDATE_SUCCESS = '[company] UPDATE SUCCESS';

export const DELETE = '[company] DELETE';
export const DELETE_SUCCESS = '[company] DELETE SUCCESS';

export const SET_EDIT_ID = '[company] SET EDIT ID';

export class SetEditID implements Action {
  readonly type = SET_EDIT_ID;
  constructor(public payload: number) {}
}

export class LoadAll implements Action {
  readonly type = LOAD_ALL;
  constructor(public payload = null) {};
}

export class SearchAll implements Action {
  readonly type = SEARCH_ALL;
  constructor(public payload: string) {};
}

export class Load implements Action {
  readonly type = LOAD;
  constructor(public payload: number) {}
}

export class Create implements Action {
  readonly type = CREATE;
  constructor(public payload: Company) {};
}

export class Update implements Action {
  readonly type = UPDATE;
  constructor(public payload: Company) {};
}

export class Delete implements Action {
  readonly type = DELETE;
  constructor(public payload: number) {};
}

export class LoadAllSuccess implements Action {
  readonly type = LOAD_ALL_SUCCESS;
  constructor(public payload: Company[]) {}
}

export class SearchAllSuccess implements Action {
  readonly type = SEARCH_ALL_SUCCESS;
  constructor(public payload: Company[]) {}
}


export class LoadSuccess implements Action {
  readonly type = LOAD_SUCCESS;
  constructor(public payload: Company) {}
}

export class CreateSuccess implements Action {
  readonly type = CREATE_SUCCESS;
  constructor(public payload: Company) {}
}

export class UpdateSuccess implements Action {
  readonly type = UPDATE_SUCCESS;
  constructor(public payload: Company) {}
}

export class DeleteSuccess implements Action {
  readonly type = DELETE_SUCCESS;
  constructor(public payload: number) {}
}

export type All =
  | SetEditID
  | LoadAll
  | SearchAll
  | Load
  | Create
  | Update
  | Delete
  | LoadAllSuccess
  | SearchAllSuccess
  | LoadSuccess
  | UpdateSuccess
  | CreateSuccess
  | DeleteSuccess;
