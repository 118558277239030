import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PasswordValidators} from '../password.validators';
import {LoginService} from '@app-services/login.service';

@Component({
  selector: 'app-activate',
  templateUrl: './activate.component.html',
  styleUrls: ['./activate.component.scss']
})
export class ActivateComponent implements OnInit {
  uuidExpiredMessage: boolean;
  passwordResetMessage: boolean;
  form: FormGroup;
  uuid: any;
  sendRequest: boolean;

  constructor(
    fb: FormBuilder,
    private router: ActivatedRoute,
    private loginService: LoginService,
  ) {
    this.form = fb.group({
      'password': ['', Validators.required],
      'confirmPassword': ['', Validators.required ]
    }, {
      validator: PasswordValidators.passwordsShouldMatch
    });
    this.passwordResetMessage = false;
    this.uuidExpiredMessage = false;
  }

  ngOnInit() {
    this.router.params.subscribe(params => {
      this.uuid = params['id'];
    });
  }

  get password() { return this.form.get('password'); }
  get confirmPassword() { return this.form.get('confirmPassword'); }

  onSubmit(form: FormGroup ) {
    this.sendRequest = !this.sendRequest;
    this.loginService.resetPassword(this.uuid, form.value)
      .subscribe(res => {
        this.passwordResetMessage = !this.passwordResetMessage;
      }, err => {
        if (err.status === 400) {
          this.uuidExpiredMessage = !this.uuidExpiredMessage;
        }

        this.sendRequest = !this.sendRequest;
        return false;
      });
  }
}
