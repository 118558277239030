import {Injectable} from '@angular/core';
import {DataService} from '@app-services/data.service';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Pib} from '@app-routes/pib/pib.model';
import {Piblesson} from '@app-routes/pib/piblesson.model';

@Injectable()
export class PibCompanyService extends DataService {

  constructor(http: HttpClient) {
    const pibUrl = environment.api_base_url + '/pibcompany';
    super(pibUrl, http);
  }

  getCompanyPIB() {
    const projection = '';
    return this.getAll(projection).map((data: any) => { return data as Pib[]; });
  }

  getSingle(id: number) {
    let url = this.url + '/' + id;
    return this.http.get(url).map( (data: any) => { return data as Pib; });
  }

  getReportFile(id: number) {
    return this.http.get(this.url + "/" + id + "/report", {responseType: "blob"});
  }

  getReportsZip(id: number) {
    return this.http.get(this.url + "/" + id + "/zip", {responseType: "blob"});
  }

  getLesson(id: number) {
    let url = this.url + '/lesson/' + id;
    return this.http.get(url).map( (data: any) => { return data as Piblesson; });
  }

  getPibLessons() {
    let url = this.url + '/lessons';
    return this.http.get(url).map( (data: any) => { return data as Piblesson[]; });
  }

  getPiblessonReportFile(personId: number) {
    return this.http.get(this.url + "/piblesson/" + personId + "/report", {responseType: "blob"});
  }
}
