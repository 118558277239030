import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class DashboardService {
  private url: string;

  constructor(
    private http: HttpClient
  ) {
    this.url = environment.api_base_url + '/dashboard';
  }

  getStats() {
    return this.http.get(this.url + '/stats').map((data: any) => { return data as Dashboard; });
  }
}

export interface Dashboard {
  entryCount: number;
  lessonCount: number;
  timeBetweenIntake: number;
  arbeidsverzuimBegin: number;
  arbeidsverzuimEind: number;
  lessons: Array<any>;
  individuals: Array<any>;


}
